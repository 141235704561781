.App {
  text-align: center;
  overflow: hidden;
  background-color: #4A412A;
}

.App-logo {
  width: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-fly infinite 3s linear;
  }
}

.App-header {
  background-color: #4A412A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-fly {
  from {
    transform: translate(-40vmin)
  }
  to {
    transform: translate(calc(100vw))
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
